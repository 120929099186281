import React from 'react'
import { Carousel, Container, Col, Row } from 'react-bootstrap'
import { MappedImage } from '../Image'
import { mapSubModuleFields } from '../../utils'

export const TestimonialCarousel = ({ heading, subModules }) => {
  return (
    <Container fluid className="p-5 text-center">
      <div className="mw">
        <div className="module-heading-wrapper w-100 my-4">
          <h2 className="module-heading m-auto">{heading}</h2>
        </div>
        <section aria-label={'testimonial carousel section'}>
          <Carousel
            aria-label={'testimonial carousel'}
            variant={'dark'}
            indicators={true}
            keyboard={true}
            pause={'hover'}
            wrap={false}
            interval={null}
          >
            {subModules &&
              subModules?.map(item => (
                <Carousel.Item key={item.heading} className="py-5">
                  <Row className="carousel-item-wrapper w-100 m-auto flex-column">
                    <Col>
                      <div className="image-wrapper my-5 testimonial-slider-img">
                        <MappedImage
                          image={item.image}
                          options={{ rounded: 'true' }}
                        />
                      </div>
                      <div
                        className="testimonial-text-wrapper mx-auto my-3 w-75"
                        style={{ fontSize: '1.8rem' }}
                      >
                        <em>{item.heading}</em>
                      </div>
                      <div
                        className="testimonial-name-wrapper"
                        style={{
                          fontSize: '1.4rem',
                          textTransform: 'uppercase',
                        }}
                      >
                        <strong>{item.subtitle}</strong>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>
              ))}
          </Carousel>
        </section>
      </div>
    </Container>
  )
}

export const mapTestimonialCarouselProps = props => mapSubModuleFields(props)
